<template>
  <ClientOnly>
    <template #fallback>
      <Spinner />
    </template>

    <swiper-container class="swiper-wrapper"
                      :style="{ height: 'auto' }"
                      v-bind="swiperOptions">
      <swiper-slide class="swiper-slide swiper__gallery-slide"
                    v-for="(image, index) in images"
                    :key="image.id"
                    @click="showLightBox(index)">
        <PictureImg class="swiper__gallery__img"
                    :class="imageClass"
                    :image="image" />
      </swiper-slide>
    </swiper-container>

    <vue-easy-lightbox :visible="lightBoxVisibleRef"
                       :imgs="lightBoxImagesRef"
                       :index="lightBoxIndexRef"
                       @hide="onHideLightBox" />
  </ClientOnly>
</template>

<script setup lang="ts">
import { register } from 'swiper/element/bundle';
import type { SwiperOptions } from 'swiper';
import { useEasyLightbox } from 'vue-easy-lightbox';
import merge from 'lodash/merge';
import type { IFile } from '@/types';
import { PictureImg } from '../PictureImg';
import Spinner from '../Spinner.vue';

register(true);

const props = defineProps({
  cover: {
    type: Object as PropType<IFile | null>,
    default: null,
  },
  files: {
    type: Array as PropType<IFile[]>,
    required: true,
  },
  imageClass: {
    type: String as PropType<string>,
    default: '',
  },
  swiperOptions: {
    type: Object as PropType<Partial<SwiperOptions> | null>,
    default: null,
  },
});

const images = props.files.filter((file) => file.mime.startsWith('image'));

if (props.cover) {
  images.unshift(props.cover);
}

const {
  // methods
  show: showLightBox,
  onHide: onHideLightBox,
  // refs
  visibleRef: lightBoxVisibleRef,
  imgsRef: lightBoxImagesRef,
  indexRef: lightBoxIndexRef,
} = useEasyLightbox({
  imgs: images.map((image) => ({
    src: image.url,
    alt: image.alternativeText || '',
    title: image.caption || '',
  })),
  initIndex: 0
});

const defaultSwiperOptions: SwiperOptions = {
  autoplay: true,
  loop: true,
  zoom: true,
  pagination: false,
  slideClass: 'swiper__gallery-slide',
  slidesPerView: 4,
  slidesPerGroup: 4,
  grid: {
    rows: 1,
  },
  spaceBetween: 0,
  breakpoints: {
    320: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    610: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    900: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1200: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  }
};

const swiperOptions: SwiperOptions = merge(
  defaultSwiperOptions,
  (props.swiperOptions || {}),
);
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
../PictureImg
